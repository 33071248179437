import { css } from '@emotion/core'

const style = css`
  position: absolute;
  width: 50px;
  height: 50px;
  left: 62px;
  top: 276px;
`

export default () => (
  <a href="https://github.com/k3nt0w/">
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" css={style}>
      <path
        d="M25.045 0.187447C11.2653 0.187447 0.0913239 11.5498 0.0913239 25.5672C0.0913239 36.7808 7.24132 46.2944 17.1562 49.6503C18.4033 49.8853 18.8612 49.0998 18.8612 48.4294C18.8612 47.8242 18.838 45.8249 18.8273 43.7042C11.8851 45.2395 10.4202 40.7096 10.4202 40.7096C9.28513 37.776 7.64959 36.996 7.64959 36.996C5.38554 35.4208 7.82025 35.4531 7.82025 35.4531C10.326 35.6318 11.6455 38.0686 11.6455 38.0686C13.8711 41.9486 17.4831 40.8269 18.907 40.1784C19.131 38.5384 19.7777 37.418 20.4913 36.7846C14.9488 36.1428 9.12232 33.9666 9.12232 24.2416C9.12232 21.4707 10.0971 19.2066 11.6934 17.4292C11.4343 16.7895 10.5802 14.2086 11.9351 10.7126C11.9351 10.7126 14.0306 10.0305 18.7992 13.3142C20.7897 12.7519 22.9244 12.4699 25.045 12.4602C27.1657 12.4699 29.3021 12.7519 31.2963 13.3142C36.0591 10.0305 38.1517 10.7126 38.1517 10.7126C39.5099 14.2086 38.6554 16.7895 38.3963 17.4292C39.9963 19.2066 40.9645 21.4707 40.9645 24.2416C40.9645 33.9897 35.1269 36.1361 29.5702 36.7644C30.4653 37.552 31.2628 39.0966 31.2628 41.4645C31.2628 44.8599 31.2339 47.5931 31.2339 48.4294C31.2339 49.1048 31.6831 49.8962 32.9479 49.647C42.8574 46.2872 49.9983 36.777 49.9983 25.5672C49.9983 11.5498 38.826 0.187447 25.045 0.187447"
        fill="white"
      />
      <path
        d="M9.54256 36.6274C9.4876 36.7539 9.29256 36.7913 9.11487 36.7047C8.93388 36.6219 8.83223 36.45 8.89091 36.3235C8.94463 36.1937 9.14008 36.1579 9.32066 36.2441C9.50206 36.3273 9.60537 36.5009 9.54256 36.6274"
        fill="white"
      />
      <path
        d="M10.5533 37.7739C10.4343 37.8862 10.2016 37.834 10.0438 37.6567C9.88057 37.4797 9.85 37.2431 9.97066 37.1292C10.0934 37.017 10.319 37.0695 10.4826 37.2465C10.6459 37.4255 10.6777 37.6605 10.5533 37.7739"
        fill="white"
      />
      <path
        d="M11.5372 39.2357C11.3843 39.3437 11.1343 39.2424 10.9798 39.0167C10.8269 38.791 10.8269 38.5204 10.9831 38.4119C11.138 38.3035 11.3843 38.401 11.5409 38.625C11.6934 38.8541 11.6934 39.1252 11.5372 39.2357"
        fill="white"
      />
      <path
        d="M12.8851 40.6478C12.7483 40.8012 12.457 40.7601 12.2438 40.5508C12.0256 40.3461 11.9649 40.0557 12.1021 39.9023C12.2405 39.7484 12.5335 39.7917 12.7483 39.9993C12.9649 40.2036 13.031 40.4961 12.8851 40.6478"
        fill="white"
      />
      <path
        d="M14.7446 41.4678C14.6843 41.6666 14.4037 41.757 14.1211 41.6725C13.8388 41.5855 13.6541 41.3527 13.7112 41.1518C13.7698 40.9517 14.0517 40.8576 14.3364 40.9479C14.6182 41.0345 14.8033 41.2657 14.7446 41.4678"
        fill="white"
      />
      <path
        d="M16.7868 41.62C16.7938 41.8293 16.5541 42.0028 16.2574 42.0062C15.9591 42.0133 15.7178 41.844 15.7145 41.638C15.7145 41.4266 15.9488 41.2547 16.2471 41.2497C16.5438 41.2438 16.7868 41.4119 16.7868 41.62"
        fill="white"
      />
      <path
        d="M18.6872 41.2909C18.7227 41.4951 18.5165 41.7049 18.2219 41.7603C17.9322 41.8146 17.6641 41.6885 17.6273 41.4859C17.5913 41.2766 17.8012 41.0669 18.0905 41.0127C18.3855 40.9605 18.6496 41.0833 18.6872 41.2909"
        fill="white"
      />
    </svg>
  </a>
)
