import React from 'react'
import Name from './Name'
import Github from './Github'
import Facebook from './Facebook'
import Twitter from './Twitter'
import Hatena from './Hatena'
import { Global, css } from '@emotion/core'

const globalStyle = css`
  html {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #3300ff 0%, rgba(255, 0, 0, 0.51) 100%);
  }
`

export default () => (
  <div>
    <Global styles={globalStyle} />
    <Name />
    <Github />
    <Facebook />
    <Twitter />
    <Hatena />
  </div>
)
