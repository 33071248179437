import { css } from '@emotion/core'

const style = css`
  position: absolute;
  width: 50px;
  height: 50px;
  left: 131px;
  top: 276px;
`

export default () => (
  <a href="https://www.facebook.com/kento.watanabe.7731">
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" css={style}>
      <path
        d="M50 25.1523C50 11.2596 38.8086 0 25 0C11.1914 0 0 11.2596 0 25.1523C0 37.7088 9.14062 48.1136 21.0938 50V32.4229H14.7461V25.1523H21.0938V19.6109C21.0938 13.3081 24.8242 9.82511 30.5371 9.82511C33.2715 9.82511 36.1328 10.3164 36.1328 10.3164V16.5062H32.9785C29.873 16.5062 28.9062 18.4467 28.9062 20.4362V25.1523H35.8398L34.7314 32.4229H28.9062V50C40.8594 48.1136 50 37.7088 50 25.1523Z"
        fill="white"
      />
    </svg>
  </a>
)
