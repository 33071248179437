import { css } from '@emotion/core'
import src from './hatena.png'

const style = css`
  position: absolute;
  width: 78px;
  height: 78px;
  left: 256px;
  top: 262px;
`

export default () => (
  <a href="https://www.mathgram.xyz/">
    <img src={src} css={style} />
  </a>
)
